import { createSlice } from "@reduxjs/toolkit"

const configSlice = createSlice({
  name: 'config',
  initialState: {
    themeMode: localStorage.getItem("themeMode") !== null ? localStorage.getItem("themeMode"): "light",
    filter: {
      city: null,
      type: null,
      categoryId: null,
      priceSince: null,
      priceTo: null,
    },
    propertySelected: null
  },
  reducers: {
    setThemeMode(state) {
      const currentThemeMode = state.themeMode;
      state.themeMode = currentThemeMode === "dark" ? "light" : "dark";
      localStorage.setItem("themeMode", state.themeMode);
    },
    setFilter(state, action) {
      state.filter = action.payload;
    },
    setProperty(state, action) {
      state.propertySelected = action.payload;
    }
  },
});

export const { setThemeMode, setFilter, setProperty } = configSlice.actions;
export default configSlice.reducer;