import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from "material-react-table";
import { Alert, Box, Button, IconButton, Tooltip} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteProperty, getCategories, getCities, getProperties, postImages, postProperty, putProperty } from "../../../services/admin_services";
import { AddHomeOutlined } from "@mui/icons-material";
import Create from "./Create";
import Update from "./Update";
import { translate_table } from "../../../utils/translations";

 const MainProperties = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const [data, setData] = useState([])
  const [categories, setCategories] = useState([])
  const [cities, setCities] = useState([])
  const [stateRequest, setStateRequest] = useState({})
  const [updateData, setUpdateData] = useState(false)
  const [cancelEdit, setCancelEdit] = useState(true)
  const [imgsPost, setImgsPost] = useState({formData:[], length:0})
  const [imgsPut, setImgsPut] = useState([])
  const [imgsDeleted, setImgsDeleted] = useState([])

  const propertyTypes = [
    { value: 'alquiler', label: 'Alquiler' },
    { value: 'venta', label: 'Venta' }
  ];

  const pets = [
    { value: null, label: 'Sin info' },
    { value: true, label: 'Si' },
    { value: false, label: 'No' },
  ];

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
        visible:false
      },
      {
        accessorKey: "title",
        header: "Título",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.title,
          helperText: validationErrors?.title,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              title: undefined,
            }),
        },
      },

      {
        accessorKey: "description",
        header: "Descripción",
        size: 500,
      },

      {
        accessorKey: "address",
        header: "Dirección"
      },
      {
        accessorKey: "location",
        header: "Ubicación en maps"
      },
      {
        accessorKey: "price",
        header: "Precio",
        size: 50,
        defaultValue: null,
        muiEditTextFieldProps: {
          error: !!validationErrors?.price,
          helperText: validationErrors?.price,
        },
      },

      {
        accessorKey: "currency",
        header: "Moneda",
        editVariant: "select",
        editSelectOptions: [
          { value: 'usd', label: 'USD' },
          { value: 'ars', label: 'ARS' }
        ],
        size: 50,
      },

      {
        accessorKey: "type",
        header: "Tipo de propiedad",
        editVariant: "select",
        editSelectOptions: propertyTypes,
        muiEditTextFieldProps: {
          select: true,
          error: !!validationErrors?.type,
          helperText: validationErrors?.type,
        },
        size: 50
      },

      {
        accessorKey: "categoryName",
        header: "Categoría",
        editVariant: "select",
        editSelectOptions: categories,
        muiEditTextFieldProps: {
          select: true,
          error: !!validationErrors?.category,
          helperText: validationErrors?.category,
        },
        size: 50,
      },

      {
        accessorKey: "width",
        header: "Ancho",
        size: 50,
        defaultValue: null,
        muiEditTextFieldProps: {
          error: !!validationErrors?.width,
          helperText: validationErrors?.width,
        },
      },
      {
        accessorKey: "height",
        header: "Largo",
        size: 50,
        defaultValue: null,
        muiEditTextFieldProps: {
          error: !!validationErrors?.height,
          helperText: validationErrors?.height,
        },
      },

      {
        accessorKey: "coveredArea",
        header: "Area cubierta",
        size: 50,
        defaultValue: null,
        muiEditTextFieldProps: {
          error: !!validationErrors?.coveredArea,
          helperText: validationErrors?.coveredArea,
        },
      },

      {
        accessorKey: "totalArea",
        header: "Area total",
        size: 50,
        defaultValue: null,
        muiEditTextFieldProps: {
          error: !!validationErrors?.totalArea,
          helperText: validationErrors?.totalArea,
        },
      },

      {
        accessorKey: "city",
        header: "Ciudad",
        editVariant: "select",
        editSelectOptions: cities,
        muiEditTextFieldProps: {
          select: true,
          error: !!validationErrors?.city,
          helperText: validationErrors?.city,
        },
        size: 80,
      },

      {
        accessorKey: "rooms",
        header: "Ambientes",
        size: 50,
        defaultValue: null,
      },

      {
        accessorKey: "bedrooms",
        header: "Dormitorios",
        size: 50,
        muiEditTextFieldProps: {
          error: !!validationErrors?.bedrooms,
          helperText: validationErrors?.bedrooms,
        },
        defaultValue: null,
      },

      {
        accessorKey: "bathrooms",
        header: "Baños",
        size: 50,
        muiEditTextFieldProps: {
          error: !!validationErrors?.bathrooms,
          helperText: validationErrors?.bathrooms,
        },
        defaultValue: null,
      },

      {
        accessorKey: "petDet",
        header: "Mascotas",
        editVariant: "select",
        editSelectOptions: pets,
        defaultValue: null,
      },
    
    ],
    //eslint-disable-next-line
    [validationErrors]
  );

  useEffect(() => {
    const getPropertiesFunc = async () => {
      const {result, status} = await getProperties({}, 1, 20000);
      if (status === 200) {
        setData(result);
      } else {setStateRequest({state: "error", message:"Error al obtener propiedades"})}
    };

    const getCategoriesFunc = async () => {
      const {result, status} = await getCategories();
      if (status === 200) {
        const mappedCategories = result?.map(c => ({ label: c.name, value: c.id }));
        setCategories(mappedCategories)
      } else{setStateRequest({state: "error", message:"Error al obtener categorías"})}
      };

      const getCitiesFunc = async () => {
        const {result, status} = await getCities();
        if (status === 200) {
          const mappedCities = result?.map(c => ({ label: c.name, value: c.id }));
          setCities(mappedCities)
        } else{setStateRequest({state: "error", message:"Error al obtener ciudades"})}
        };

    getCategoriesFunc();
    getCitiesFunc();
    getPropertiesFunc();
  }, [updateData, cancelEdit]);

  


  //CREATE action
  const handleCreateProperty = async ({ values, table }) => {
    try {
      values.categoryId = values.categoryName
      values.pets = values.petDet
      values.price = parseFloat(values.price)
      values.coveredArea = parseFloat(values.coveredArea)
      values.totalArea = parseFloat(values.totalArea)
      values.height = parseFloat(values.height)
      values.width = parseFloat(values.width)

      if(values.location?.includes('style="border:0;"')) {
        values.location = values.location?.replace('style="border:0;"', 'style={{border:0}}')
      } 
      if(values.location?.includes('width="600"')){
        values.location = values.location?.replace('width="600"', 'width="100%"')
      }
  
      const newValidationErrors = validateProperty(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      delete values.id
      //Ejecutar servicio
      const {result, status} = await postProperty(values);
  
      if (status === 200) {
        if (imgsPost.length > 0) {
          const status = await postImages(imgsPost.formData, result);
          if (status !== 200) {
            setStateRequest({
              state: "error",
              message: `Error al actualizar imágenes de la propiedad`,
            });
            return;
          }
        }
        setStateRequest({
          state: "success",
          message: `Se actualizó propiedad ${values.title}`,
        });
        setUpdateData(!updateData);
        setImgsPost(null)
        table.setCreatingRow(null); //exit creating mode
    }} catch (error) {
      console.error("Error handleCreateProperty: " + error)
    }
  };

  //UPDATE action
  const handleSaveProperty = async ({ values, table }) => {
    try {
      let categoria;
      if (typeof values.categoryName === "string") {
        const found = categories.find((item) => item.label === values.categoryName);
        categoria = found.value;
      } else {
        categoria = values.categoryName;
      }
      values.categoryId = categoria;
      values.pets = values.petDet
      values.price = parseFloat(values.price)
      values.coveredArea = parseFloat(values.coveredArea)
      values.totalArea = parseFloat(values.totalArea)
      values.height = parseFloat(values.height)
      values.width = parseFloat(values.width)

      if(values.location?.includes('style="border:0;"')) {
        values.location = values.location?.replace('style="border:0;"', 'style={{border:0}}')
      } 
      if(values.location?.includes('width="600"')){
        values.location = values?.location.replace('width="600"', 'width="100%"')
      }

      const petDetToBoolean = (petDet) =>
        petDet === "si" ? true : petDet === "no" ? false : null;
      values.pets = petDetToBoolean(values.petDet);
      values.images = imgsPut;
      values.imgsDeleted = imgsDeleted;
  
      const newValidationErrors = validateProperty(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
  
      const { result, status } = await putProperty(values);
      if (status === 200) {
        if (imgsPost.length > 0) {
          const status = await postImages(imgsPost.formData, result);
          if (status !== 200) {
            setStateRequest({
              state: "error",
              message: `Error al actualizar imágenes de la propiedad`,
            });
            return;
          }
        }
        
        setStateRequest({
          state: "success",
          message: `Se actualizó propiedad ${values.title}`,
        });
        setUpdateData(!updateData);
      } else {
        setStateRequest({
          state: "error",
          message: `Error al actualizar propiedad: ${result}`,
        });
        return;
      }
  
      setTimeout(() => {
        setStateRequest({});
      }, 5000);

      setImgsPost(null)
      setImgsDeleted([])

      table.setEditingRow(null); //exit editing mode
    } catch (error) {
      console.error("Error handleSaveProperty: " + error);
    }
  };

  //DELETE action
  const openDeleteConfirmModal = async (row) => {
    if (window.confirm("Eliminar propiedad?")) {
      await deleteProperty(row.original.id);
      setUpdateData(!updateData)
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: data,
    createDisplayMode: "modal", //default ('row', and 'custom' are also available)
    editDisplayMode: "modal", //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row) => row.id,
    /* muiToolbarAlertBannerProps: isLoadingUsersError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined, */
    initialState: {
      columnVisibility: { id: false },
      pagination: {
        pageSize: 100,
      },
    },
    localization:translate_table,
    muiTopToolbarProps: { sx: { pt: "2rem", pl: "2rem" } },
    muiTableProps: { sx: { pl: "2rem" } },
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    onCreatingRowCancel:() => {
      setValidationErrors({});
      setCancelEdit(!cancelEdit);
      setImgsDeleted([]);
      setImgsPost([]);
    },

    onCreatingRowSave: handleCreateProperty,
    onEditingRowCancel: () => {
      setValidationErrors({});
      setCancelEdit(!cancelEdit);
      setImgsDeleted([]);
      setImgsPost([]);
    },

    onEditingRowSave: handleSaveProperty,
    //optionally customize modal content
    renderCreateRowDialogContent: ({ table, row, internalEditComponents}) => (
      <Create
        internalEditComponents={internalEditComponents}
        table={table}
        row={row}
        title="Propiedad"
        setImgsPost={setImgsPost}
      />
    ),
    //optionally customize modal content
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <Update
        internalEditComponents={internalEditComponents}
        table={table}
        row={row}
        title="Propiedad"
        setImgsPut={setImgsPut}
        imgsPut={imgsPut}
        setImgsPost={setImgsPost}
        setImgsDeleted={setImgsDeleted}
        imgsDeleted ={imgsDeleted}
      />
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Editar">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eliminar">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        endIcon={<AddHomeOutlined />}
        onClick={() => {
          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          // table.setCreatingRow(
          //   createRow(table, {
          //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
          //   }),
          // );
        }}
      >
        Crear propiedad
      </Button>
    ),
  });

  return (
    <>
      {stateRequest.state && (
        <Alert variant="filled" severity={stateRequest.state}>
          {stateRequest.message}
        </Alert>
      )}
      <MaterialReactTable table={table} sx={{ margin: "2rem" }} />{" "}
    </>
  );
};



const validateRequired = (value) => (value !== undefined && value !== null) && !!String(value).trim().length;
const validateProperty = (property) => {
  const { title, type, categoryId, city, bedrooms, bathrooms, price, coveredArea, totalArea, rooms, width, height } = property;
  return {
    title: !validateRequired(title) ? "El título es requerido" : "",
    type: !validateRequired(type) ? "El tipo de propiedad es requerido" : "",
    category: !validateRequired(categoryId) ? "La categoría es requerida" : "",
    city: !validateRequired(city) ? "La ciudad es requerida" : "",
    bedrooms: (
      (+bedrooms > +rooms) ||
      (!(/^\d+$/.test(bedrooms)) && bedrooms !== "" && bedrooms !== null)
    ) ? "La cantidad de dormitorios no puede ser mayor a la cantidad de habitaciones y debe ser un número entero" : "",
    bathrooms: (
      (+bathrooms > +rooms) ||
      (!(/^\d+$/.test(bathrooms)) && bathrooms !== "" && bathrooms !== null)
    ) ? "La cantidad de baños no puede ser mayor a la cantidad de habitaciones y debe ser un número entero" : "",
    price: !(/^\d+(\.\d+)?$/.test(price)) && price !== "" && price !== null ? "El precio debe ser un número decimal (ej: 3.2)" : "",
    height: !(/^\d+(\.\d+)?$/.test(height)) && height !== "" && height !== null ? "La altura debe ser un número decimal (ej: 3.2)" : "",
    width: !(/^\d+(\.\d+)?$/.test(width)) && width !== "" && width !== null ? "El ancho debe ser un número decimal (ej: 3.2)" : "",
    coveredArea: !(/^\d+(\.\d+)?$/.test(coveredArea)) && coveredArea !== "" && coveredArea !== null ? "El área cubierta debe ser un número decimal (ej: 3.2)" : "",
    totalArea: !(/^\d+(\.\d+)?$/.test(totalArea)) && totalArea !== "" && totalArea !== null ? "El área total debe ser un número decimal (ej: 3.2)" : "",
    rooms: !(/^\d+$/.test(rooms)) && rooms !== "" && rooms !== null ? "El número de habitaciones debe ser un número entero" : "",
  };
};

export default MainProperties;