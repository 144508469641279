import React from 'react'
import { IconButton, Tooltip } from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { useDispatch, useSelector } from 'react-redux';
import { setThemeMode } from '../../../redux/Config/slices/configSlice';



export const ThemeSwitch = () => {
      
    const {themeMode} = useSelector((state) => state.config);
    const dispatch = useDispatch();
    const dark = themeMode === "dark";

    return (
        <IconButton onClick={() => dispatch(setThemeMode())} >
          <Tooltip 
          title={dark ? "Cambiar a modo claro" : "Cambiar a modo oscuro"}
          PopperProps={{style:{zIndex:8889}}}
          >
            {dark 
            ?  <DarkModeOutlinedIcon />
            :  <LightModeOutlinedIcon />
            }
          </Tooltip>
        </IconButton>
    )
}

