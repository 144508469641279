import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  Button,
  Box,
  Container,
  ListItemText,
  ListItemIcon,
  List,
  ListItem,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { Business, Home, Mail, WhatsApp } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-scroll';

const pages = [
  { label: 'Propiedades', icon: <Home />, link:"properties" },
  { label: 'Sobre nosotros', icon: <Business />, link:"about-us"  },
  { label: 'Contacto', icon: <Mail />, link:"contact" },
];

const ResponsiveAppBar = () => {
  const classes = useStyles();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null); // Cierra el menú desplegable
  };

  const handleWhatsapp = () => {
    const url = `https://api.whatsapp.com/send?phone=+543482388020`;
    window.open(url, '_blank');
}

  return (
    <AppBar
      sx={{ backgroundColor: "terra.primary", position: "fixed", top: 0 }}
    >
      <Container maxWidth="xl">
        <Toolbar
          className={classes.toolBar}
          sx={{ mx: { xs: "-1rem", md: "4rem" } }}
        >
          <Box className={classes.brand}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              className={classes.icon}
            >
              <ApartmentIcon />
            </IconButton>
            <Typography
              variant="h6"
              component={Link}
              to="book"
              spy={true}
              smooth={true}
              duration={500}
              className={classes.title}
              onClick={() => window.location.pathname !== "/" && (window.location.href = "/")}
            >
              TERRA SERVICIOS INMOBILIARIOS
            </Typography>
          </Box>
          <Box className={classes.navLinks}>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page.label}
                  color="inherit"
                  className={classes.navButton}
                  offset={window.innerHeight / 2} // Ajusta el desplazamiento al centro
                >
                  <Link to={page.link} spy={true} smooth={true} duration={500} onClick={() => window.location.pathname !== "/" && (window.location.href = "/")}>
                    {page.label}
                  </Link>
                </Button>
              ))}
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                color="inherit"
                onClick={handleOpenNavMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{ mt: "2rem" }}
              >
                <List>
                  {pages.map((page) => (
                    <ListItem
                      key={page.label}
                      onClick={handleCloseNavMenu}
                      sx={{ color: "btntxt.main" }}
                    >
                      <ListItemIcon sx={{ color: "btntxt.main" }}>
                        {page.icon}
                      </ListItemIcon>
                      <Link
                        to={page.link}
                        spy={true}
                        smooth={true}
                        duration={500}
                        onClick={() => window.location.pathname !== "/" && (window.location.href = "/")}
                      >
                        <ListItemText primary={page.label} />
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </Menu>
            </Box>
          </Box>

          <IconButton
            onClick={handleWhatsapp}
            color="terraLight"
            variant="contained"
            sx={{
              position: "absolute",
              right: isMobile ? 50 : -20,
              color: "btntxt.main",
            }}
          >
            <WhatsApp />
          </IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems:"center"
  },
  brand: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    marginRight: theme.spacing(2),
    cursor:'pointer'
  },
  title: {
    color: theme.palette.common.white,
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  icon: {
    color: theme.palette.common.white,
  },
  navLinks: {
    display: 'flex',
    alignItems: 'center',
  },
  navButton: {
    marginLeft: theme.spacing(2),
    textTransform: 'capitalize',
  },
}));

export default ResponsiveAppBar;