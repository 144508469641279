import { Box } from '@mui/material'
import React from 'react'
import ResponsiveAppBar from './components/ResponsiveAppBar'
import Book from './components/Book'
import Properties from './components/Properties'
import AboutUs from './components/AboutUs'
import Contact from './components/Contact'

const Home = () => {
  return (
    <Box>
      <ResponsiveAppBar/>
      <Book/>
      <Properties/>
      <AboutUs/>
      <Contact/>
    </Box>
  )
}

export default Home
