import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react'

const Header = ({ title, subtitle, isCard=false }) => {
  const {breakpoints} = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('lg'))

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Typography variant={isMobile ? "h3" : "h2"} sx={{ color: isCard ? "text" :"btntxt.main", fontWeight: "500" }}>
        {title}
      </Typography>
      <Typography variant="body1" color="terra.primary" mb=".5rem">
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header
