import { Box } from '@mui/material'
import React from 'react'
import PersistentDrawerLeft from './components/Drawer'

const Admin = () => {
  return (
    <Box>
      <PersistentDrawerLeft/>
    </Box>
  )
}

export default Admin
