import { DialogActions, DialogContent, DialogTitle } from '@mui/material'
//eslint-disable-next-line
import { MRT_EditActionButtons } from 'material-react-table'
import React from 'react'
import ImageUpdate from './ImageUpdate'

const Update = ({ table, row, internalEditComponents, title, setImgsPut, imgsPut, setImgsPost, setImgsDeleted, imgsDeleted}) => {
  
  //Seteamos el array de imagenes
  if (title === "Propiedad") {
    setImgsPut(row.original.images)
  }

  return (
    <>
        <DialogTitle variant="h3" bgcolor="background.primary">Editar {title}</DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1.5rem", bgcolor:"background.primary"}}
        >
          {internalEditComponents} {/* or render custom edit components here */}
          {
            (title=== "Propiedad") &&
            <ImageUpdate setImgsPut={setImgsPut} imgsPut={imgsPut} setImgsPost={setImgsPost} setImgsDeleted={setImgsDeleted} imgsDeleted={imgsDeleted}/>
          }
        </DialogContent>
        <DialogActions sx={{bgcolor:"background.primary"}}>
          {/* eslint-disable-next-line */}
          <MRT_EditActionButtons variant="text" table={table} row={row}/>
        </DialogActions>
      </>
  )
}

export default Update
