import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ThemeSwitch } from '../../Home/components/ThemeSwitch';
import { useCookies } from 'react-cookie';
import Logout from '../../Login/Logout';
import { Category, Home, LocationCity } from '@mui/icons-material';
import MainProperties from './MainProperties';
import MainCategories from './MainCategories';
import MainCities from './MainCities';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    /* padding: theme.spacing(3), */
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}));

export default function PersistentDrawerLeft() {
  const theme = useTheme();
  const {palette} = theme;
  //const isMobile = useMediaQuery(breakpoints.down('lg'));
  const [open, setOpen] = useState(true);
  const [selected, setSelected] = useState("property")

  //eslint-disable-next-line
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const {user} = cookies;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" open={open}>
        <Toolbar
          sx={{ backgroundColor: "background.main", color: "text.main" }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {user}
          </Typography>
          <Logout />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor:`${palette.background.main} !important`
          },
        }}
        variant={"persistent"}
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <ThemeSwitch />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => setSelected("property")}>
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText primary={"Propiedades"} />
            </ListItemButton>
          </ListItem>
        <Divider sx={{my:".4rem"}}/>
          <ListItem disablePadding>
            <ListItemButton onClick={() => setSelected("category")}>
              <ListItemIcon>
                <Category />
              </ListItemIcon>
              <ListItemText primary={"Categorías"} />
            </ListItemButton>
          </ListItem>
        <Divider sx={{my:".4rem"}}/>
          <ListItem disablePadding>
            <ListItemButton onClick={() => setSelected("cities")}>
              <ListItemIcon>
                <LocationCity />
              </ListItemIcon>
              <ListItemText primary={"Localidades"} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Main open={open} sx={{backgroundColor:"background.secondary"}}>
        <DrawerHeader />
        {selected === "property" ? (
            <MainProperties />
          ) : selected === "cities" ? (
            <MainCities />
          ) : (
            <MainCategories />
          )}
      </Main>
    </Box>
  );
}