import getCookie from "../utils/getCookie";
const token = getCookie("token");
// Obtener el valor de la cookie "CTTO_v2"
const CTTO_v2 = getCookie("CTTO_v2");

const headers = new Headers();
const headersImage = new Headers();
headers.append('Cookie', `${CTTO_v2}; SameSite=None; Secure; `);
headersImage.append('Cookie', `${CTTO_v2}; SameSite=None; Secure; `);
headers.set("Accept", "application/json");
headersImage.set("Accept", "application/json");
headers.set("x-csrf-token", token);
headersImage.set("x-csrf-token", token);

headers.append("Content-Type", "application/json");

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
// +++++++++++++++++++++++++ SERVICIOS DE PROPERTIES, CATEGORIES Y CITIES  ++++++++++++++++++++++++++++++++++++++++++++++++++
export const getProperties = async (params = {}, page = 1, limit = 6) => {
  try {
    const {
      city = params.city ===''  || params.city === undefined ? null : params.city,
      currency = params.currency ===''  || params.currency === undefined ? null : params.currency,
      type = params.type === '' || params.type === undefined  ? null : params.type,
      categoryId = params.categoryId === '' || params.categoryId === undefined ? null : params.categoryId,
      priceSince = params.priceSince === '' || params.priceSince === 0 || params.priceSince === undefined  ? null : params.priceSince,
      priceTo = params.priceTo === '' || params.priceTo === 0 || params.priceTo === undefined  ? null : params.priceTo
    } = params;


    const response = await fetch(SERVER_URL + `/properties?city=${city}&type=${type}&categoryId=${categoryId}&priceSince=${priceSince}&priceTo=${priceTo}&currency=${currency}&page=${page}&limit=${limit}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const { result, status, totalPages } = await response.json();
    return { result, status, totalPages };
  } catch (e) {
    console.log("Error al obtener propiedades, ", e);
    return { status: e };
  }
};

export const getOneProperty = async (id) => {
  try {
    const response = await fetch(SERVER_URL + `/properties/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const { result, status } = await response.json();
    return { result, status };
  } catch (e) {
    console.log("Error al obtener propiedades, ", e);
    return { status: e };
  }
};

export const postProperty = async (data) => {
  try {
    const response = await fetch(SERVER_URL + "/properties", {
      method: "POST",
      credentials: "include",
      mode: "cors",
      headers,
      body: JSON.stringify(data),
    });

    const { status, result } = await response.json();
    return {status, result};
  } catch (e) {
    throw Error("No se puede crear propiedad " + e);
  }
};

export const putProperty = async (data) => {
  try {
    const response = await fetch(SERVER_URL + `/properties/${data.id}`, {
      method: "PUT",
      credentials: "include",
      mode: "cors",
      headers,
      body: JSON.stringify(data),
    });

    const { status, result } = await response.json();
    return {status, result};
  } catch (e) {
    throw Error("No se puede modificar propiedad " + e);
  }
};

export const deleteProperty = async (id) => {
  try {
    const response = await fetch(SERVER_URL + `/properties/${id}`, {
      method: "DELETE",
      credentials: "include",
      mode: "cors",
      headers,
    });

    const { status } = await response.json();
    return status;
  } catch (e) {
    throw Error("No se puede eliminar propiedad " + e);
  }
};

export const getCategories = async () => {
  try {
    const response = await fetch(SERVER_URL + "/categories", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const { result, status } = await response.json();
    return { result, status };
  } catch (e) {
    console.log("Error al obtener categorías, ", e);
    return { status: e };
  }
};

export const postCategory = async (data) => {
  try {
    const response = await fetch(SERVER_URL + "/categories", {
      method: "POST",
      credentials: "include",
      mode: "cors",
      headers,
      body: JSON.stringify(data),
    });

    const { status } = await response.json();
    return status;
  } catch (e) {
    throw Error("No se puede crear categoría " + e);
  }
};

export const putCategory = async (data) => {
  try {
    const response = await fetch(SERVER_URL + `/categories/${data.id}`, {
      method: "PUT",
      credentials: "include",
      mode: "cors",
      headers,
      body: JSON.stringify(data),
    });

    const { status } = await response.json();
    return status;
  } catch (e) {
    throw Error("No se puede modificar categoría " + e);
  }
};

export const deleteCategory = async (id) => {
  try {
    const response = await fetch(SERVER_URL + `/categories/${id}`, {
      method: "DELETE",
      credentials: "include",
      mode: "cors",
      headers,
    });

    const { status } = await response.json();
    return status;
  } catch (e) {
    throw Error("No se puede eliminar categoría " + e);
  }
};

export const getCities = async () => {
  try {
    const response = await fetch(SERVER_URL + "/cities", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const { result, status } = await response.json();
    return { result, status };
  } catch (e) {
    console.log("Error al obtener ciudades, ", e);
    return { status: e };
  }
};

export const postCity = async (data) => {
  try {
    const response = await fetch(SERVER_URL + "/cities", {
      method: "POST",
      credentials: "include",
      mode: "cors",
      headers,
      body: JSON.stringify(data),
    });

    const { status } = await response.json();
    return status;
  } catch (e) {
    throw Error("No se puede crear ciudad " + e);
  }
};

export const putCity = async (data) => {
  try {
    const response = await fetch(SERVER_URL + `/cities/${data.id}`, {
      method: "PUT",
      credentials: "include",
      mode: "cors",
      headers,
      body: JSON.stringify(data),
    });

    const { status } = await response.json();
    return status;
  } catch (e) {
    throw Error("No se puede modificar ciudad " + e);
  }
};

export const deleteCity = async (id) => {
  try {
    const response = await fetch(SERVER_URL + `/cities/${id}`, {
      method: "DELETE",
      credentials: "include",
      mode: "cors",
      headers,
    });

    const { status } = await response.json();
    return status;
  } catch (e) {
    throw Error("No se puede eliminar ciudad " + e);
  }
};

export const postImages = async (data, propId) => {
  try {
     //No body no es json
    const response = await fetch(SERVER_URL + `/images/${propId}`, {
      method: "POST",
      credentials: "include",
      mode: "cors",
      headersImage,
      body: data,
    });

    const { status } = await response.json();
    return status;
  } catch (e) {
    throw Error("No se pueden crear imagenes " + e);
  }
};