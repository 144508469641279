import React, { useEffect, useMemo, useState } from 'react'
import { deleteCity, getCities, postCity, putCity } from '../../../services/admin_services';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Alert, Box, Button, IconButton, Tooltip } from '@mui/material';
import { ControlPoint, Delete, Edit } from '@mui/icons-material';
import Create from './Create';
import Update from './Update';
import { translate_table } from '../../../utils/translations';

const MainCities = () => {
    const [validationErrors, setValidationErrors] = useState({});
    const [data, setData] = useState([])
    const [stateRequest, setStateRequest] = useState({})
    const [updateData, setUpdateData] = useState(false)
  
    const columns = useMemo(
      () => [
        {
          accessorKey: "id",
          header: "Id",
          enableEditing: false,
          size: 80,
        },
        {
          accessorKey: "name",
          header: "Nombre de ciudad",
          muiEditTextFieldProps: {
            required: true,
            error: !!validationErrors?.name,
            helperText: validationErrors?.name,
            //remove any previous validation errors when user focuses on the input
            onFocus: () =>
              setValidationErrors({
                ...validationErrors,
                title: undefined,
              }),
          },
        }
      
      ],
      //eslint-disable-next-line
      [validationErrors]
    );
  
    useEffect(() => {
      const getCitiesFunc = async () => {
        const {result, status} = await getCities();
        if (status === 200) {
          setData(result);
        } else{ setStateRequest({state:"error", message: `Error al obetener ciudades`}) }
        setTimeout(() => {setStateRequest({})}, 5000);
      };
  
      getCitiesFunc();
    }, [updateData]);
  
  
    //CREATE action
    const handleCreateCity = async ({ values, table }) => {
      const newValidationErrors = validateCity(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      delete values.id
      setValidationErrors({});
      //Ejecutar servicio
      const result = await postCity(values);
  
      if (result === 200) {
        setStateRequest({state:"success", message: `Se insertó ciudad ${values.name}`})
        setUpdateData(!updateData)
      } else{ setStateRequest({state:"error", message: `Error al insertar ciudad`}) }
      setTimeout(() => {setStateRequest({})}, 5000);
      table.setCreatingRow(null); //exit creating mode
    };
  
    //UPDATE action
    const handleSaveCity = async ({ values, table }) => {
      const newValidationErrors = validateCity(values)
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      const result = await putCity(values);
      if (result === 200) {
        setStateRequest({state:"success", message: `Se cambió el nombre de la ciudad a ${values.name}`})
      } else{ setStateRequest({state:"error", message: `Error al actualizar ciudad`}) }
  
      setTimeout(() => {setStateRequest({})}, 5000);
  
      table.setEditingRow(null); //exit editing mode
    };
  
    //DELETE action
    const openDeleteConfirmModal = (row) => {
      if (window.confirm("Eliminar ciudad?")) {
        deleteCity(row.original.id);
        setUpdateData(!updateData)
      }
    };
  
    const table = useMaterialReactTable({
      columns,
      data: data,
      createDisplayMode: "modal", //default ('row', and 'custom' are also available)
      editDisplayMode: "modal", //default ('row', 'cell', 'table', and 'custom' are also available)
      enableEditing: true,
      getRowId: (row) => row.id,
      /* muiToolbarAlertBannerProps: isLoadingUsersError
        ? {
            color: "error",
            children: "Error loading data",
          }
        : undefined, */
      localization:translate_table,
      muiTopToolbarProps:{sx:{pt:"2rem", pl:"2rem", maxWidth:"90%"}},
      muiBottomToolbarProps:{sx:{ maxWidth:"90%"}},
      muiTableProps:{sx:{pl:'2rem'}},
      initialState:{
        columnVisibility: { id: false },
      },
      muiTableContainerProps: {
        sx: {
          minHeight: "450px",
          maxWidth:"90%"
        },
      },
      onCreatingRowCancel: () => setValidationErrors({}),
      onCreatingRowSave: handleCreateCity,
      onEditingRowCancel: () => setValidationErrors({}),
      onEditingRowSave: handleSaveCity,
      //optionally customize modal content
      renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
        <Create internalEditComponents={internalEditComponents} table={table} row={row} title="Ciudad"/>
      ),
      //optionally customize modal content
      renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
        <Update internalEditComponents={internalEditComponents} table={table} row={row} title="Ciudad"/>
      ),
      renderRowActions: ({ row, table }) => (
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Tooltip title="Editar">
            <IconButton onClick={() => table.setEditingRow(row)}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar">
            <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
      ),
      renderTopToolbarCustomActions: ({ table }) => (
        <Button
          variant="contained"
          endIcon={<ControlPoint/>}
          onClick={() => {
            table.setCreatingRow(true); //simplest way to open the create row modal with no default values
            //or you can pass in a row object to set default values with the `createRow` helper function
            // table.setCreatingRow(
            //   createRow(table, {
            //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
            //   }),
            // );
          }}
        >
          Crear ciudad
        </Button>
      )
    });
  
    return (
      <>
        {
          stateRequest.state && 
          <Alert variant="filled" severity={stateRequest.state}>{stateRequest.message}</Alert>
        }
          <MaterialReactTable table={table} />
      </>
    );
  };
  
  
  
  const validateRequired = (value) => (value !== undefined && value !== null) && !!String(value).trim().length;
  function validateCity(city) {
    return {
      name: !validateRequired(city.name)? "El nombre de la ciudad es requerido": ""
    };
  }
  
export default MainCities
