import { Close } from '@mui/icons-material';
import { Box, IconButton, Input, InputLabel } from '@mui/material'
import React from 'react'
const SERVER_URL = process.env.REACT_APP_SERVER_URL;



const ImageUpdate = ({setImgsPut, imgsPut, setImgsPost, setImgsDeleted, imgsDeleted}) => {

  const handleAdd = (event) => {
      const formData = new FormData();
      const files = event.target.files;
      // Verificar si se seleccionaron más de 10 archivos
      if (files.length > 10) {
          alert("Solo se permiten un máximo de 10 imágenes");
          return; // Salir de la función si se excede el límite
      }

      // Agregar los archivos modificados al FormData
      for (let i = 0; i < files.length; i++) {
          formData.append('file', files[i]);
      }

      setImgsPost({formData: formData, length: files.length});
  };

  const handleDelete = (indice, image) => {
    //eliminamos el elemento con el indice x
    const newArr = imgsPut.splice(indice, 1);
    setImgsPut(newArr)
    //Agregamos el elemento al array de imagenes eliminadas
    setImgsDeleted([...imgsDeleted, image])
  };
  
  return (
    <Box display="flex" flexWrap="wrap" gap={2}>
      {imgsPut.map((image, index) => (
        <Box key={index} width={{ xs: '100%', sm: 'calc(50% - 8px)', md: 'calc(33.33% - 8px)', lg: 'calc(50% - 8px)' }} position="relative">
            <img src={`${SERVER_URL}/${image.name}`} alt={` ${index}`} style={{ width: '100%', height: 'auto' }} />
            <IconButton
              color="danger"
              aria-label="Eliminar"
              onClick={() => handleDelete(index, image)}
              style={{ position: 'absolute', top: 2, right: 2}}
            >
              <Close />
            </IconButton>
        </Box>
      ))}

        <form action="/upload" method="post" encType="multipart/form-data">
          <InputLabel htmlFor="file-upload">Imagenes (máximo 10)</InputLabel>
          <Input
              id="file-upload"
              type="file"
              inputProps={{ multiple: true }}
              onChange={handleAdd}
          />
        </form>
    </Box>
  );
}

export default ImageUpdate
