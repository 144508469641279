import React, { useEffect, useState } from 'react'
import { getProperties } from '../../../services/admin_services'
import { Box, useMediaQuery, CircularProgress, Typography, Alert, Stack, Pagination } from '@mui/material'
import Property from './Property'
import Header from './Header'
import EmptyResult from './EmptyResult'
import { useTheme } from '@emotion/react'
import { useSelector } from 'react-redux';

const Properties = () => {
  const [data, setData] = useState([]);
  const [stateRequest, setStateRequest] = useState({});
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("lg"));
  const { filter } = useSelector((state) => state.config);
  const [loading, setLoading] = useState(true);
  const [emptyResult, setEmptyResult] = useState(false);
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const responsiveGrid = {
    xs: "span 12",
    sm: "span 6",
    md: "span 6",
    lg: "span 4",
    xl: "span 4",
  };

  useEffect(() => {
    const getPropertiesFunc = async () => {
      const { result, status, totalPages } = await getProperties(filter, page);
      if (status === 200) {
        //eslint-disable-next-line
        if (result.length === 0) {
          setEmptyResult(true);
        } else {
          setEmptyResult(false);
        }
        setTotalPages(totalPages)
        setData(result);
      } else {
        setStateRequest({
          state: "error",
          message: "Error al obtener propiedades",
        });
      }

      setLoading(false);
    };

    getPropertiesFunc();
    setTimeout(() => {
      setStateRequest({})
    }, 5000);
  }, [filter, page]);
  
  const handlePagination = (e, page) =>{
    setPage(page)
  }

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          height="30rem"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap="1.7rem"
        >
          <CircularProgress color="terraLight" size="11rem" />
          <Typography variant="h4">Cargando propiedades...</Typography>
        </Box>
      ) : emptyResult ? (
        <EmptyResult />
      ) : (
        <Box
          id={"properties"}
          m="20px"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {/* row 1 */}
          <Box display="flex" justifyContent="center" mb=".7rem">
            <Header
              title={"Propiedades"}
              subtitle={"Propiedades encontradas"}
              isCard
            />
          </Box>
          {/* row 2 */}
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={isMobile ? "20px" : "45px"}
            minWidth={!isMobile && "70%"}
          >
            {data.map((prop) => (
              <Box gridColumn={responsiveGrid} key={prop.id} my={".5rem"}>
                <Property property={prop} />
              </Box>
            ))}
          </Box>

          <Stack spacing={2} mt={1}>
            <Pagination count={totalPages} color='terraLight' onChange={handlePagination}/>
          </Stack>

          {stateRequest.state && (
          <Alert variant="filled" severity={stateRequest.state} sx={{mt:"1.2rem", width:"100%"}}>
            {stateRequest.message}
          </Alert>
        )}
        </Box>
      )}
    </>
  );
}

export default Properties
