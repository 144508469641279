import { createSlice } from "@reduxjs/toolkit"

const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        images: [],
    },
    reducers: {
        setImages(state, action) {
            state.images = action.payload;
        }
    }
})


export const { setImages } = adminSlice.actions
export default adminSlice.reducer