import { Alert, Box, Button, MenuItem, TextField, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Header from './Header'
import { makeStyles } from '@mui/styles';
import { getCategories } from '../../../services/admin_services';
import { useDispatch } from 'react-redux';
import { setFilter } from '../../../redux/Config/slices/configSlice';

const FilterForm = () => {
    const classes = useStyles();
    const [categories, setCategories] = useState([]);
    const [stateRequest, setStateRequest] = useState({})
    const { breakpoints } = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('lg'));
    const dispatch = useDispatch();
    const [filterForm, setFilterForm] = useState({city:null, type:null, categoryId: null, priceSince:null, priceTo:null})
  
    useEffect(() => {
      const getCategoriesFunc = async () => {
        const { result, status } = await getCategories();
        if (status === 200) {
          setCategories(result);
        } else {
          setStateRequest({ state: "error", message: "Error al obtener categorías" });
        }
      };
  
      getCategoriesFunc();

      setTimeout(() => {
        setStateRequest({})
      }, 5000);

    }, []);


    const handleSearch = () =>{
      dispatch(setFilter(filterForm))
    }
  
    return (
      <Box
        className={classes.boxForm}
        sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        //minHeight={isMobile && "80%"}
        left={isMobile ? "45%" : "50%"}
      >
        <Header
          title={"Bienvenido a Terra"}
          subtitle={"¿Qué tipo de propiedad estás buscando?"}
        />
        <form className={classes.form}>
          <TextField
            label="Localidad"
            fullWidth
            color="terraLight"
            variant="standard"
            defaultValue={""}
            sx={{
              "& label.MuiInputLabel-root": { color: "#f2f0f0" },
              "& input": { color: "#f2f0f0" },
            }}
            onChange={(e) =>
              setFilterForm({ ...filterForm, city: e.target.value })
            }
          />
          <Box sx={{ display: "flex", gap: "1.5rem" }}>
            <TextField
              variant="standard"
              color="terraLight"
              select
              label="Seleccione acción"
              sx={{
                "& label.MuiInputLabel-root": { color: "#f2f0f0" },
                "& .MuiSelect-select.MuiSelect-select": { color: "#f2f0f0" },
              }}
              helperText={
                isMobile
                  ? ""
                  : "¿Estás pensando en comprar o alquilar una propiedad?"
              }
              FormHelperTextProps={{ sx: { color: "#f2f0f0" } }}
              fullWidth
              defaultValue={""}
              onChange={(e) =>
                setFilterForm({ ...filterForm, type: e.target.value })
              }
            >
              <MenuItem value={"venta"} sx={{ color: "#f2f0f0" }}>
                Comprar
              </MenuItem>
              <MenuItem value={"alquiler"} sx={{ color: "#f2f0f0" }}>
                Alquilar
              </MenuItem>
            </TextField>
            <TextField
              color="terraLight"
              variant="standard"
              select
              label="Tipo de propiedad"
              sx={{
                "& label.MuiInputLabel-root": { color: "#f2f0f0" },
                "& .MuiSelect-select.MuiSelect-select": { color: "#f2f0f0" },
              }}
              helperText={
                isMobile ? "" : "¿Qué tipo de propiedad deseas encontrar?"
              }
              FormHelperTextProps={{ sx: { color: "#f2f0f0" } }}
              fullWidth
              defaultValue={""} 
              onChange={(e) =>
                setFilterForm({ ...filterForm, categoryId: e.target.value })
              }
            >
              <MenuItem value={""} sx={{ color: "#f2f0f0" }}>
                Todas
              </MenuItem>
              {categories?.map((cat) => (
                <MenuItem key={cat.id} value={cat.id} sx={{ color: "#f2f0f0" }}>
                  {cat.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box display="flex" gap="1.5rem">
            <TextField
              className={classes.input}
              type="number"
              color="terraLight"
              variant="standard"
              label="Precio desde"
              sx={{
                "& label.MuiInputLabel-root": { color: "#f2f0f0" },
                "& input": { color: "#f2f0f0" },
              }}
              fullWidth={!isMobile}
              defaultValue={""}
              onChange={(e) =>
                setFilterForm({ ...filterForm, priceSince: +e.target.value })
              }
            />
            <TextField
              className={classes.input}
              type="number"
              color="terraLight"
              variant="standard"
              label="Precio hasta"
              sx={{
                "& label.MuiInputLabel-root": { color: "#f2f0f0" },
                "& input": { color: "#f2f0f0" },
              }}
              fullWidth={!isMobile}
              defaultValue={""}
              onChange={(e) =>
                setFilterForm({ ...filterForm, priceTo: +e.target.value })
              }
            />
            <TextField
              variant="standard"
              color="terraLight"
              select
              label="Moneda"
              sx={{
                "& label.MuiInputLabel-root": { color: "#f2f0f0" },
                "& .MuiSelect-select.MuiSelect-select": { color: "#f2f0f0" },
              }}
    
              FormHelperTextProps={{ sx: { color: "#f2f0f0" } }}
              fullWidth
              defaultValue={"ars"}
              onChange={(e) =>
                setFilterForm({ ...filterForm, currency: e.target.value })
              }
            >
              <MenuItem value={"ars"} sx={{ color: "#f2f0f0" }}>
                ARS
              </MenuItem>
              <MenuItem value={"usd"} sx={{ color: "#f2f0f0" }}>
                USD
              </MenuItem>
            </TextField>
          </Box>

          <Button
            variant="contained"
            color="primary"
            sx={{ width: "100%", bgcolor: "background.home" }}
            onClick={handleSearch}
          >
            Buscar
          </Button>
        </form>

        {stateRequest.state && (
          <Alert variant="filled" severity={stateRequest.state} sx={{mt:"1.2rem", width:"100%"}}>
            {stateRequest.message}
          </Alert>
        )}
      </Box>
    );
  };
  
  const useStyles = makeStyles(
    (theme) => ({
        boxForm: {
          position: "absolute",
          top: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          padding: theme.spacing(4),
          maxHeight: "90%",
          marginInline: "1rem",
          borderRadius: ".8rem",
          maxWidth: "calc(100% - 2rem)",
          width: "30rem", // Ajustar el ancho máximo para evitar que el formulario se vuelva demasiado ancho en pantallas grandes
          display: "flex", // Centrar verticalmente el contenido
          flexDirection: "column", // Asegurar que los elementos se apilen verticalmente
          alignItems: "center", // Centrar verticalmente los elementos hijos
        },
        form: {
          display: "flex",
          gap: "1.5rem",
          flexDirection: "column",
        },
        row: {
          display: "flex",
          gap: "1.5rem",
          flexDirection: "column", // Cambiar la dirección de la columna para dispositivos móviles
          [theme.breakpoints.up("md")]: {
            flexDirection: "row", // Volver a la dirección de la fila para dispositivos grandes
          },
        },
        input: {
          "& input[type=number]": {
            "-moz-appearance": "textfield",
          },
          "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
        },
      }
    )
  );
  
export default FilterForm
