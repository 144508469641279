import { WhatsApp } from '@mui/icons-material';
import {Button} from "@mui/material";
import React from 'react'

const WhatsappButton = ({link}) => {

    const handleWhatsapp = () => {
      // Genera el texto automático con el enlace de la propiedad
      const message = encodeURIComponent(`¡Hola! Estoy interesado en esta propiedad ${link}`);
      // URL de la API de WhatsApp con el número del vendedor y el texto automático
      const url = `https://api.whatsapp.com/send?phone=+543482388020&text=${message}`;
      // Abre la conversación de WhatsApp en una nueva pestaña
      window.open(url, '_blank');
    };
  
  return (
    <Button
      onClick={handleWhatsapp}
      color='terraLight'
      endIcon={<WhatsApp/>}
      variant="contained"
      fullWidth
      sx={{mt:".5rem", color:"btntxt.main"}}
    >
      Consultar
    </Button>
  );
}

export default WhatsappButton
