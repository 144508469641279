import React from 'react';
import { Box, Typography, Paper, useTheme, useMediaQuery} from '@mui/material';
import keyImage from '../../../assets/key.jpg';


const AboutUs = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box px={isMobile ? 2 : 8} py={2} id={"about-us"}>
      <Paper
        elevation={3}
        sx={{
          bgcolor: theme.palette.secondary.main,
          p: 3,
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          gap: "3rem",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          maxWidth={isMobile ? "100%" : "70%"}
        >
          <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
            NUESTRA PRIORIDAD SOS VOS
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: theme.palette.text.primary }}
          >
            Somos una empresa de servicios inmobiliarios caracterizada por su
            seriedad, agilidad y transparencia, formada por un equipo
            multidisciplinario de profesionales que garantizan el mejor
            asesoramiento, seguridad y eficiencia al momento de efectuar
            operaciones inmobiliarias. Creada para ofrecer una experiencia
            innovadora, creativa y personalizada para cada necesidad, respetando
            los más altos estándares en control de informes registrales y
            situación jurídica de los inmuebles, enfocados plenamente en lograr
            una tasación certera, precisa y acorde a las condiciones del
            mercado, potenciando de esta manera la agilidad para concretar
            operaciones de forma rápida y oportuna.
          </Typography>
        
          <Typography>
            Te ofrecemos un servicio de TASACIÓN PROFESIONAL, encontrando el
            valor óptimo de mercado, potenciando de esta manera la rápida
            aparición de ofertas y aumentando considerablemente la posibilidad
            de concretar la venta. Aportando además, la mayor visibilidad
            posible en portales digitales, redes sociales y plena difusión en
            nuestra amplia red de contactos, con un trabajo de alta calidad que
            aporta un mejor posicionamiento entre las ofertas del mercado.
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={isMobile ? "100%" : "30%"}
        >
          <img
            src={keyImage}
            alt="Key"
            style={{
              maxWidth: "100%",
              height: "auto",
              borderRadius: theme.spacing(1),
              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
            }}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default AboutUs;