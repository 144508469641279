import { Box,  Typography, useTheme, useMediaQuery, Chip, Divider, Avatar } from "@mui/material";
import {  AttachMoney, BedroomChild, CropOriginal, CropSquare, Height, Home, LocationOn, MeetingRoom, Pets, Shower, SyncAlt } from "@mui/icons-material";
import Slider from "./Slider";
import { capitalizeFirstLetter } from "../../../utils/utils";
import WhatsappButton from "./WhatsappButton";
import { useEffect, useState } from "react";
import { getOneProperty } from "../../../services/admin_services";
import { useParams } from 'react-router-dom';
import ResponsiveAppBar from "./ResponsiveAppBar";

const style = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "95%",
  bgcolor: 'background.primary',
  boxShadow: 24,
  p: 4,
  height:"95%",
  mt:8,
  pb:8,
  overflow:"scroll",
  outline:"none",
  display:"grid",
  gridTemplateColumns:"repeat(12, 1fr)",
    "&::-webkit-scrollbar": {
      width: "8px", // Ancho del scroll bar
      height: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888", // Color del scroll thumb
      borderRadius: "4px", // Borde redondeado del thumb
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#555", // Color del thumb al pasar el cursor sobre él
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1", // Color del track del scroll bar
      borderRadius: "8px", // Borde redondeado del track
    },
};



export default function DisplayedProperty() {

  let { id } = useParams();

  const [propertySelected, setPropertySelected] = useState(null)
  const {
    title,
    description,
    address,
    type,
    price,
    width,
    height,
    coveredArea,
    totalArea,
    rooms,
    bedrooms,
    bathrooms,
    pets,
    images,
    currency,
    location
  } = propertySelected || {};
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const getProperty = async() =>{
      const {result} = await getOneProperty(id);
      setPropertySelected(result)
    }

    getProperty();
  }, [id])
  

  return (
    <Box>
      <ResponsiveAppBar />
      <Box sx={style} gap={!isMobile && "3rem"}>
        <Box gridColumn={isMobile ? "span 12" : "span 9"} display="flex" flexDirection="column" gap="1rem">
          <Box display="flex" flexDirection="column" maxHeight="100vh" >
            <Slider images={images} />
          </Box>
          <Box sx={{ border: 'none' }} dangerouslySetInnerHTML={{ __html: location }}/>
        </Box>
        <Box
          gridColumn={isMobile ? "span 12" : "span 3"}
          display="flex"
          flexDirection="column"
          gap={isMobile ? ".5rem" : "1rem"}
          sx={{ mt: isMobile && "1.5rem" }}
        >
          <Typography fontSize="1.5rem">{title}</Typography>
          <Divider color="#bcac97" sx={{ mt: "-.5rem" }} />
          {description && (
            <Typography variant="body1" my={".3rem"}>
              {description}
            </Typography>
          )}
          {price && (
            <Chip
              icon={
                currency === "usd" ? (
                  <Avatar
                    sx={{ bgcolor: "transparent", fontSize: ".9rem", m: -5 }}
                  >
                    USD
                  </Avatar>
                ) : (
                  <AttachMoney />
                )
              }
              label={price}
              sx={{ width: "100%", my: "0.5rem", fontSize: "1rem" }}
            />
          )}
          <Box display="flex" flexWrap="wrap" gap={1.1}>
            {address && (
              <Chip
                icon={<LocationOn />}
                label={capitalizeFirstLetter(address)}
              />
            )}
            {type && (
              <Chip icon={<Home />} label={capitalizeFirstLetter(type)} />
            )}
            {width && (
              <Chip icon={<SyncAlt />} label={`Ancho terreno: ${width}`} />
            )}
            {height && (
              <Chip icon={<Height />} label={`Largo terreno: ${height}`} />
            )}
            {coveredArea && (
              <Chip
                icon={<CropOriginal />}
                label={`Area cubierta: ${coveredArea}`}
              />
            )}
            {totalArea && (
              <Chip icon={<CropSquare />} label={`Area total: ${totalArea}`} />
            )}
            {rooms && (
              <Chip icon={<MeetingRoom />} label={`Ambientes: ${rooms}`} />
            )}
            {bedrooms && (
              <Chip
                icon={<BedroomChild />}
                label={`Dormitorios: ${bedrooms}`}
              />
            )}
            {bathrooms && (
              <Chip icon={<Shower />} label={`Baños: ${bathrooms}`} />
            )}
            {(pets || pets === 0) && (
              <Chip icon={<Pets />} label={`Mascotas: ${pets ? "Si" : "No"}`} />
            )}
          </Box>
          <Box>
            <WhatsappButton link={window.location.href} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}