import {  useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        terra: {
          100: "#705743",
          200: "#bcac97",
          300: "#b3ac8f",
        },
        grey: {
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
          1000: "#c2c2c2"
        },
        primary: {
          100: "#d0d1d5",
          200: "#a1a4ab",
          300: "#727681",
          400: "#424242",
          500: "#141b2d",
          600: "#101624",
          700: "#0c101b",
          800: "#080b12",
          900: "#040509",
        },
        greenAccent: {
          100: "#dbf5ee",
          200: "#b7ebde",
          300: "#94e2cd",
          400: "#70d8bd",
          500: "#47817F",
          600: "#3da58a",
          700: "#2e7c67",
          800: "#1e5245",
          900: "#0f2922",
          1000:"#94e2cd"
        },
        redAccent: {
          100: "#f8dcdb",
          200: "#f1b9b7",
          300: "#e99592",
          400: "#e2726e",
          500: "#db4f4a",
          600: "#af3f3b",
          700: "#832f2c",
          800: "#58201e",
          900: "#2c100f",
        },
        blueAccent: {
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "#a4a9fc",
          400: "#868dfb",
          500: "#6870fa",
          600: "#535ac8",
          700: "#3e4396",
          800: "#2a2d64",
          900: "#151632",
        },
      }
    : {
      terra: {
          100: "#705743",
          200: "#bcac97",
          300: "#b3ac8f",
        },
        grey: {
          100: "#141414",
          200: "#292929",
          300: "#3d3d3d",
          400: "#525252",
          500: "#666666",
          600: "#858585",
          700: "#a3a3a3",
          800: "#c2c2c2",
          900: "#e0e0e0",
          1000:"#c2c2c2"
        },
        primary: {
          100: "#040509",
          200: "#080b12",
          300: "#0c101b",
          400: "#f2f0f0", // manually changed
          500: "#141b2d",
          600: "#1F2A40",
          700: "#727681",
          800: "#a1a4ab",
          900: "#ffff",
        },
        greenAccent: {
          100: "#0f2922",
          200: "#1e5245",
          300: "#2e7c67",
          400: "#3da58a",
          500: "#47817F",
          600: "#70d8bd",
          700: "#94e2cd",
          800: "#b7ebde",
          900: "#dbf5ee",
          1000:"#47817F"
        },
        redAccent: {
          100: "#2c100f",
          200: "#58201e",
          300: "#832f2c",
          400: "#af3f3b",
          500: "#db4f4a",
          600: "#e2726e",
          700: "#e99592",
          800: "#f1b9b7",
          900: "#f8dcdb",
        },
        blueAccent: {
          100: "#151632",
          200: "#2a2d64",
          300: "#3e4396",
          400: "#535ac8",
          500: "#6870fa",
          600: "#868dfb",
          700: "#a4a9fc",
          800: "#c3c6fd",
          900: "#e1e2fe",
        },
      }),
});

// mui theme settings
export const themeSettings = (mode) => {

  const colors = tokens(mode);
  return {
    colors,
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            terra: {
              main: colors.terra[100],
              primary: colors.terra[200],
              secondary: colors.terra[300],
            },
            terraLight: {
              main: colors.terra[200],
            },
            primary: {
              main: colors.terra[100],
            },
            secondary: {
              main: colors.primary[400],
            },
            background: {
              main: colors.primary[900],
              primary: colors.primary[400],
              secondary: colors.grey[800],
              opacity: colors.grey[801],
            },
            btntxt: {
              main: "#F9F9F9"
            },
            text:{
              main: colors.grey[100],
            }
          }
        : {
            // palette values for light mode
            terra: {
              main: colors.terra[100],
              primary: colors.terra[200],
              secondary: colors.terra[300],
            },
            terraLight: {
              main: colors.terra[200],
            },
            primary: {
              main: colors.terra[100],
            },
            secondary: {
              main: colors.primary[400],
            },
            background: {
              main: colors.primary[900],
              primary: colors.primary[400]
            },
            btntxt: {
              main: "#F9F9F9"
            },
            text:{
              main: colors.grey[100]
            }
          }),
    },
    typography: {
      fontSize: 12,
      h1: {
        fontSize: 40,
      },
      h2: {
        fontSize: 32,
      },
      h3: {
        fontSize: 26,
      },
      h4: {
        fontSize: 20,
      },
      h5: {
        fontSize: 16,
      },
      h6: {
        fontSize: 14,
      },
    },

    // Overrides Styles - COMPONENTS (Los que no nos sirvan)
    components: {
      "MuiContainer": {
        styleOverrides: {
          root: {
            "@media (min-width: 600px)": {
              padding: 0
            }
          }
        }
      },
      "MuiButton": {
        styleOverrides: {
          sizeSmall: {
            // Estilos específicos para botones con el tamaño "small"
            maxHeight: "1.7rem",
          },
        }
      },
      "MuiPaper": {
        styleOverrides: {
          root: {
            background: colors.terra[200],
            '&.MuiDrawer-paper': {
              backgroundColor: colors.terra[200]
            }
          }
        }
      },
      "MuiListItemButton":
      {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: colors.terra[100]
            },
          }
        }
      },
      "MuiTab":
      {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              color: colors.terra[100]
            },
          }
        }
      }
    }
  }
};

// // context for color mode
export const useTheme = ()=>{
  
  const {themeMode} = useSelector((state) => state.config)


  const theme = useMemo(() => createTheme(themeSettings(themeMode)), [themeMode]);
  return theme
}