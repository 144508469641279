import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Divider,
  Avatar,
} from '@mui/material';
import emailjs from '@emailjs/browser';
import logoBlack from '../../../assets/image.png'


const Contact = () => {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [result, setResult] = useState(null)
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false)
    
      const handleEmail = (e) => {
        e.preventDefault();

        // Verifica si alguno de los campos está vacío
        if (!name || !email || !message) {
          setError("Por favor, completa todos los campos");
          return;
        }

        setLoading(true)
        // Prepara los datos del formulario para ser enviados
        const templateParams = {
          user_name: name,
          user_email: email,
          message: message,
        };

        // Envía el formulario utilizando EmailJS
        emailjs
          .send(
            "service_nq1r4ub",
            "template_ig553te",
            templateParams,
            "qkMoKje839fv4LEw6"
          )
          .then((response) => {
            console.log("Correo electrónico enviado con éxito:", response);
            setLoading(false)
            setResult({
              type: "success",
              message: "Mensaje enviado exitosamente",
            });
          })
          .catch((error) => {
            console.error("Error al enviar el correo electrónico:", error);
            setLoading(false)
            setResult({ type: "error", message: "Error al enviar el mensaje" });
            // Aquí puedes manejar el error, como mostrar un mensaje de error al usuario
          });

          emailjs
          .send(
            "service_g9pscul",
            "template_plo410p",
            templateParams,
            "qkMoKje839fv4LEw6"
          )
          .then((response) => {
            console.log("Correo electrónico enviado con éxito:", response);
            setLoading(false)
            setResult({
              type: "success",
              message: "Mensaje enviado exitosamente",
            });
          })
          .catch((error) => {
            console.error("Error al enviar el correo electrónico:", error);
            setLoading(false)
            setResult({ type: "error", message: "Error al enviar el mensaje" });
            // Aquí puedes manejar el error, como mostrar un mensaje de error al usuario
          });

        // Limpia los campos del formulario después de enviar el correo electrónico
        setName("");
        setEmail("");
        setMessage("");
        setError("");
        setTimeout(() => {
          setResult(null);
        }, 10000);
      };

  return (
    <Box p={2} textAlign="center" mb="1.1rem" id={"contact"}>
      <Typography variant="h3" gutterBottom>
        Contacto
      </Typography>
      <Box maxWidth={600} margin="auto">
        <form>
          <TextField
            disabled={loading}
            label="Nombre"
            variant="outlined"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            disabled={loading}
            label="Correo Electrónico"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            disabled={loading}
            label="Mensaje"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            margin="normal"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            sx={{ mt: 2 }}
            onClick={handleEmail}
          >
            {!loading ? "Enviar Consulta" : "Enviando consulta..." }
          </Button>
          {error && <Alert severity="error" sx={{ mt:"1rem"}} fullWidth>{error}</Alert>}
          {result &&
            <Alert severity={result.type} sx={{ mt:"1rem"}} fullWidth>{result.message}</Alert>
          }
        </form>
      </Box>
      <Divider sx={{my:2}}/>
      <Box  display="flex" flexDirection="column" alignItems="center">
      <Avatar
          sx={{
            width: 150,
            height: 150,
            marginBlock: ".5rem",
            '& img': {
              width: '100%',
              height: '100%',
              //objectFit: 'cover', // Para asegurar que la imagen se ajuste correctamente dentro del Avatar
            }
          }}
        >
          <img src={logoBlack} alt="logo" />
        </Avatar>
        <Typography variant="h4">Información de Contacto</Typography>
        <Typography variant="body1">
          Dirección: Calle Amenabar, 1266, Reconquista, Santa Fe 
        </Typography>
        <Typography variant="body1">Teléfono: +543482388020</Typography>
        <Typography variant="h6">
          Correo Electrónico
        </Typography>
        <Typography variant="body1">
         juandaran@terraserviciosinmobiliarios.com 
        </Typography>
        <Typography variant="body1">alexistortul@terraserviciosinmobiliarios.com</Typography>
        <Typography variant="body1">federicobrassart@terraserviciosinmobiliarios.com</Typography>
      </Box>
  
    </Box>
  );
};

export default Contact;