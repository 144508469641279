import { createSlice } from "@reduxjs/toolkit"


const cookiesSlice = createSlice({
    name: 'cookies',
    initialState: {
        expTime: localStorage.getItem('expTime') !== null ? localStorage.getItem('expTime') : null,
    },
    reducers: {
        setExpTime(state, action) {
            state.expTime = action.payload
            localStorage.setItem('expTime', state.expTime)
        }
    }
})


export const { setExpTime } = cookiesSlice.actions
export default cookiesSlice.reducer