import { Box, Typography } from '@mui/material'
import React from 'react'
import { useCookies } from 'react-cookie';
import { LogoutOutlined } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { setExpTime } from '../../redux/Login/slices/cookiesSlice';
//import { useCountdown } from '../../hooks/useCountdown';

const Logout = () => {
    //eslint-disable-next-line
    const [cookies, setCookie, removeCookie] = useCookies([]);
    // const {expTime} = useSelector((state) => state.cookies)
    const dispatch = useDispatch();
    //eslint-disable-next-line
    // const [days, hours, minutes, seconds] = useCountdown(expTime);
    // const {user} = cookies;

    const removeCookies = () => {
        removeCookie('token')
        removeCookie('CTTO_v2')
        removeCookie('user')
        dispatch(setExpTime(null))
    }
    

  return (
    <Box
      position={"fixed"}
      right={15}
    >
      <Typography display="flex">
        Cerrar sesión
        <span
          style={{marginLeft:"8px", cursor: "pointer" }}
          onClick={removeCookies}
        >
          <LogoutOutlined/>
        </span>
      </Typography>
    </Box>
  );
}

export default Logout
