import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Provider } from 'react-redux';
import NotFound from './components/NotFound';
import Home from './pages/Home/Home';
import store from './redux/store'
import Login from './pages/Login/Login'
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useTheme } from './theme';
import Admin from './pages/Admin/Admin'
import DisplayedProperty from './pages/Home/components/DisplayedProperty';


function App() {
  return (
    <Provider store={store}>
      <ReduxConnectedApp />
    </Provider>
  );
}


function ReduxConnectedApp() {

  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/*' element={<NotFound/>} />
          <Route path='/login' element={<Login/>} />
          <Route path='/propiedades/:id' element={<DisplayedProperty/>}/>
          <Route path='/admin' element={<ProtectedRoute><Admin/></ProtectedRoute>} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

const ProtectedRoute = ({ children }) => {
  const { pathname } = useLocation();
  //eslint-disable-next-line
  const [cookies, setCookie] = useCookies(['user']);
  const {token, CTTO_v2} = cookies;

  const isAdminRoute = pathname.startsWith('/admin');

  // Solo redirige al inicio de sesión si es una ruta de administración y no hay token o CTTO_v2
  if (isAdminRoute && (!token || !CTTO_v2 || token === 'undefined' || CTTO_v2 === 'undefined')) {
    return <Navigate to="/login" replace />;
  }

  // De lo contrario, permite el acceso a la ruta protegida
  return children;
};

export default App;
