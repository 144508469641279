import React from 'react';
import { Box, Typography } from '@mui/material';
import { SearchOff } from '@mui/icons-material'; // Importa el icono SearchOff de Material-UI

const EmptyResult = () => {
  return (
    <Box textAlign="center" m="3rem">
      <SearchOff fontSize="large" color="disabled" />
      <Typography variant="body1" color="textSecondary" mt={1}>
        No se encontraron propiedades para su búsqueda
      </Typography>
    </Box>
  );
};

export default EmptyResult
