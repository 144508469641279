import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import img1 from '../../../assets/img1.jpg';
// import img2 from '../../../assets/img6.jpg';
// import img4 from '../../../assets/img4.jpg';
import FilterForm from './FilterForm';
import { motion } from 'framer-motion';

const Book = () => {
  const classes = useStyles();
  const images=[img1]
 
  return (
    <Box className={classes.root} id={"book"}>
      <motion.div
        className={classes.imageContainer}
        initial={{ opacity: 0, y: 20 }} // Animación inicial: oculta el contenedor y lo desplaza hacia abajo
        animate={{ opacity: 1, y: 0 }} // Animación al montar: muestra el contenedor y lo restablece a su posición original
        transition={{ duration: 2 }} // Duración de la animación
      >
        {images.map((image, index) => (
          <img key={index} src={image} alt={`Imagen ${index}`} className={classes.image} style={{maxWidth: '100%'}}/>
        ))}
      </motion.div>

      <FilterForm />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop:"4rem",
    height: '80vh', // Altura predeterminada para dispositivos de mayor tamaño
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: '70vh', // Si es celular, se establece la altura a 70vh
      marginTop:"3.6rem",
    },
  },
  imageContainer: {
    position: 'absolute',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    height: 'auto',
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[3],
  },
  
}));

export default Book;