export const translate_table = {
    actions: 'Acciones',
    cancel: 'Cancelar',
    changeFilterMode: 'Cambia el modo de filtro',
    clearFilter: 'Filtro claro',
    clearSearch: 'Borrar búsqueda',
    clearSort: 'Borrar ordenamiento',
    columnActions: 'Acciones de columna',
    edit: 'Editar',
    expand: 'Expandir',
    expandAll: 'Expandir todo',
    filterByColumn: 'Filtrar por {column}',
    filterMode: 'Modo de filtro: {filterType}',
    grab: 'Agarrar',
    groupByColumn: 'Agrupar por {column}',
    groupedBy: 'Agrupados por ',
    hideAll: 'Ocultar todo',
    hideColumn: 'Ocultar columna de {column}',
    rowActions: 'Acciones de fila',
    pinToLeft: 'Alfile a la izquierda',
    pinToRight: 'Alfile a la derecha',
    save: 'Guardar',
    search: 'Búsqueda',
    selectedCountOfRowCountRowsSelected:
      '{selectedCount} de {rowCount} fila(s) seleccionadas',
    showAll: 'Mostrar todo',
    showAllColumns: 'Mostrar todas las columnas',
    showHideColumns: 'Mostrar/Ocultar columnas',
    showHideFilters: 'Alternar filtros',
    showHideSearch: 'Alternar búsqueda',
    sortByColumnAsc: 'Ordenar por {column} ascendente',
    sortByColumnDesc: 'Ordenar por {column} descendiendo',
    thenBy: ', ',
    toggleDensity: 'Alternar relleno denso',
    toggleFullScreen: 'Alternar pantalla completa',
    toggleSelectAll: 'Seleccionar todo',
    toggleSelectRow: 'Seleccionar fila',
    ungroupByColumn: 'Desagrupar por {column}',
    unpin: 'Quitar pasador',
    unsorted: 'Sin clasificar',
    move: 'Desplazar',
    rowsPerPage: 'Filas por Pagina',
    noRecordsToDisplay: 'No existe información para mostrar',
    noResultsFound: 'No se encontraron resultados',
    dropToGroupBy: 'Soltar para agrupar por {column}'
  }