import { Box, Input, InputLabel } from '@mui/material'
import React from 'react'

const ImageUpload = ({setImgsPost}) => {

    const handleImages = (event) => {
        const formData = new FormData();
        const files = event.target.files;
        // Agregar los archivos modificados al FormData
        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i]);
        }
            
        setImgsPost({formData: formData, length: files.length});
    };
  
    return (
      <Box >
        <form action="/upload" method="post" encType="multipart/form-data">
            <InputLabel htmlFor="file-upload">Imágenes</InputLabel>
            <Input
                id="file-upload"
                type="file"
                inputProps={{ multiple: true }}
                onChange={handleImages}
            />
        </form>
      </Box>
    );
}

export default ImageUpload
