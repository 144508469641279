import { Box, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { MRT_EditActionButtons } from 'material-react-table'
import React from 'react'
import ImageUpload from './ImageUpload'

const Create = ({ internalEditComponents, table, row, title, setImgsPost}) => {
  
    return (
      <Box>
        <DialogTitle variant="h3" bgcolor="background.primary">Crear {title}</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "1rem", bgcolor:"background.primary" }}>
            {internalEditComponents} {/* or render custom edit components here */}
            {
              (title === "Propiedad") &&
              <ImageUpload  setImgsPost={setImgsPost}/>
            }
        </DialogContent>
        <DialogActions sx={{ bgcolor: "background.primary" }}>
            {/* eslint-disable-next-line */}
            <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
     </Box>
    )
  }

export default Create
