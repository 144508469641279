import { ArrowBack, ArrowForward, Close } from "@mui/icons-material";
import {
  Box,
  CardMedia,
  IconButton,
  useMediaQuery,
  useTheme,
  Modal
} from "@mui/material";
import React, { useState } from "react";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Slider = ({ images }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [changeImage, setChangeImage] = useState(0);

  const handlePrevImage = () => {
    if (changeImage > 0) {
      setChangeImage(changeImage - 1);
    }
  };

  const handleNextImage = () => {
    if (changeImage < images.length - 1) {
      setChangeImage(changeImage + 1);
    }
  };

  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleOpenFullScreen = () => {
    setIsFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
  };


  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      height={isMobile ? "60%" : "80%"}
      minHeight={!isMobile && "90vh"}
      gap={!isMobile ? "20px" : "5px"}
      mx={-2}
    >
      <Box gridColumn={isMobile ? "span 12" : "span 10"}>
        <CardMedia
          image={
            images && images[changeImage]?.name
              ? `${SERVER_URL}/${images[changeImage]?.name}`
              : ""
          }
          sx={{
            width: "100%",
            position: "relative",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            height: isMobile ? "20rem" : "100%",
            transition: " 0.5s ease-in-out",
          }}
          onClick={(e) => {
            if (!e.target.closest("button")) {
              // Verifica si el evento se originó desde un botón dentro del CardMedia
              handleOpenFullScreen(); 
            }
          }}
          onTouchStart={(e) => {
            let startX = e.touches[0].pageX;
            const handleTouchMove = (event) => {
              const diff = event.touches[0].pageX - startX;
              if (diff > 10) {
                handlePrevImage();
                startX = event.touches[0].pageX;
              } else if (diff < -10) {
                handleNextImage();
                startX = event.touches[0].pageX;
              }
            };
            document.addEventListener("touchmove", handleTouchMove);
            document.addEventListener("touchend", () => {
              document.removeEventListener("touchmove", handleTouchMove);
            });
          }}
        >
          {changeImage !== 0 && (
            <IconButton
              disabled={changeImage === 0}
              onClick={handlePrevImage}
              sx={{
                zIndex: 1,
                position: "absolute",
                left: 0,
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  borderRadius: "50%",
                },
                transition: "background-color 0.3s ease-in-out", // Transición aplicada al botón previo
              }}
            >
              <ArrowBack sx={{ color: "white" }} />
            </IconButton>
          )}

          {changeImage !== images?.length - 1 && (
            <IconButton
              disabled={changeImage === images?.length - 1}
              onClick={handleNextImage}
              sx={{
                zIndex: 1,
                position: "absolute",
                right: 0,
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  borderRadius: "50%",
                },
                transition: "background-color 0.3s ease-in-out", // Transición aplicada al botón siguiente
              }}
            >
              <ArrowForward sx={{ color: "white" }} />
            </IconButton>
          )}
        </CardMedia>
        <Modal
          open={isFullScreen}
          onClose={handleCloseFullScreen}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin:"0 auto",
          }}
        >
          <div>
            <IconButton
              onClick={handleCloseFullScreen}
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
                zIndex: 9999,
              }}
            >
              <Close />
            </IconButton>
            <img
              src={
                images && images[changeImage]?.name
                  ? `${SERVER_URL}/${images[changeImage]?.name}`
                  : ""
              }
              alt=""
              style={{ maxWidth: "100%", maxHeight: "100vh" }}
            />
            {changeImage !== 0 && (
              <IconButton
                disabled={changeImage === 0}
                onClick={handlePrevImage}
                sx={{
                  zIndex: 1,
                  position: "absolute",
                  left: 0,
                  top: isMobile ? "47%" : "50%",
                  backgroundColor: "transparent",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    borderRadius: "50%",
                  },
                  transition: "background-color 0.3s ease-in-out", // Transición aplicada al botón previo
                }}
              >
                <ArrowBack sx={{ color: "white" }} />
              </IconButton>
            )}

            {changeImage !== images?.length - 1 && (
              <IconButton
                disabled={changeImage === images?.length - 1}
                onClick={handleNextImage}
                sx={{
                  zIndex: 1,
                  position: "absolute",
                  right: 0,
                  top: isMobile ? "47%" : "50%",
                  backgroundColor: "transparent",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    borderRadius: "50%",
                  },
                  transition: "background-color 0.3s ease-in-out", // Transición aplicada al botón siguiente
                }}
              >
                <ArrowForward sx={{ color: "white" }} />
              </IconButton>
            )}
          </div>
        </Modal>
      </Box>

      {/* Imágenes adicionales */}
      <Box
        mt={isMobile && 1}
        pb={"5px"}
        pr={"6px"}
        gridColumn={isMobile ? "span 12" : "span 2"}
        display="flex"
        flexDirection={!isMobile && "column"}
        //maxWidth="100%"
        gap="20px"
        height={isMobile ? "5rem" : "100%"}
        overflow="scroll"
        sx={{
          "&::-webkit-scrollbar": {
            width: "8px", // Ancho del scroll bar
            height: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888", // Color del scroll thumb
            borderRadius: "4px", // Borde redondeado del thumb
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555", // Color del thumb al pasar el cursor sobre él
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f1f1f1", // Color del track del scroll bar
            borderRadius: "8px", // Borde redondeado del track
          },
        }}
      >
        {images?.map((image, index) => (
          <Box
            key={index}
            sx={{
              width: isMobile ? "40%" : "100%",
              flexShrink: 0,
              border: index === changeImage && "4px solid",
              borderColor: "terra.primary",
              borderRadius: ".7rem",
            }}
          >
            <img
              src={image && image?.name ? `${SERVER_URL}/${image?.name}` : ""}
              alt={index + 1}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                cursor: "pointer",
                borderRadius: ".5rem",
              }}
              onClick={() => setChangeImage(index)}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Slider;
