
const SERVER_URL = process.env.REACT_APP_SERVER_URL
// +++++++++++++++++++++++++ HISTORICO MOVIMIENTOS  ++++++++++++++++++++++++++++++++++++++++++++++++++
export const getLogon = async (auth) => {
    try {
        console.log(auth)
        const response = await fetch(SERVER_URL + "/users",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'x-csrf-token': "fetch",
                    'Authorization': 'Basic '+ auth
                }
            })

            if (response.status === 200) {
                const result = await response.json();
                return {status: response.status, result: result}
            } else {
                return {status: response.status}
            }

    } catch (e) {
        console.log('Error de login, ', e)
        return {status: e}
    }
}
