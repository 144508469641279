import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from "material-react-table";
import { Alert, Box, Button, IconButton,  Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteCategory, getCategories, postCategory, putCategory } from "../../../services/admin_services";
import { ControlPoint } from "@mui/icons-material";
import Create from "./Create";
import Update from "./Update";
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { translate_table } from "../../../utils/translations";


 const MainCategories = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const [data, setData] = useState([])
  const [stateRequest, setStateRequest] = useState({})
  const [updateData, setUpdateData] = useState(false)

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "name",
        header: "Nombre de categoría",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              title: undefined,
            }),
        },
      }
    
    ],
    //eslint-disable-next-line
    [validationErrors]
  );

  useEffect(() => {
    const getCategoriesFunc = async () => {
      const {result, status} = await getCategories();
      if (status === 200) {
        setData(result);
      } else{ setStateRequest({state:"error", message: `Error al obetener categorías`}) }
      setTimeout(() => {setStateRequest({})}, 5000);
    };

    getCategoriesFunc();
  }, [updateData]);


  //CREATE action
  const handleCreateCategory = async ({ values, table }) => {
    const newValidationErrors = validateCategory(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    delete values.id
    setValidationErrors({});
    //Ejecutar servicio
    const result = await postCategory(values);

    if (result === 200) {
      setStateRequest({state:"success", message: `Se insertó categoría ${values.name}`})
      setUpdateData(!updateData)
      table.setCreatingRow(null); //exit creating mode
    } else{ setStateRequest({state:"error", message: `Error al insertar categoría`}) }
    setTimeout(() => {setStateRequest({})}, 5000);
    
  };

  //UPDATE action
  const handleSaveCategory = async ({ values, table }) => {
    const newValidationErrors = validateCategory(values)
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    const result = await putCategory(values);
    if (result === 200) {
      setStateRequest({state:"success", message: `Se cambió el nombre de la categoría a ${values.name}`})
    } else{ setStateRequest({state:"error", message: `Error al actualizar categoría`}) }

    setTimeout(() => {setStateRequest({})}, 5000);

    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row) => {
    if (window.confirm("Eliminar categoría? Esto eliminará todas las propiedades que pertenzcan a esta categoría")) {
      deleteCategory(row.original.id);
      setUpdateData(!updateData)
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: data,
    createDisplayMode: "modal", //default ('row', and 'custom' are also available)
    editDisplayMode: "modal", //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row) => row.id,
    /* muiToolbarAlertBannerProps: isLoadingUsersError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined, */
    muiTopToolbarProps:{sx:{pt:"2rem", pl:"2rem", maxWidth:"90%"}},
    localization:translate_table,
    muiBottomToolbarProps:{sx:{ maxWidth:"90%"}},
    muiTableProps:{sx:{pl:'2rem'}},
    initialState:{
      columnVisibility: { id: false },
    },
    muiTableContainerProps: {
      sx: {
        minHeight: "450px",
        maxWidth:"90%"
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateCategory,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveCategory,
    //optionally customize modal content
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <Create internalEditComponents={internalEditComponents} table={table} row={row} title="Categoría"/>
    ),
    //optionally customize modal content
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <Update internalEditComponents={internalEditComponents} table={table} row={row} title="Categoría"/>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Editar">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eliminar">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        endIcon={<ControlPoint/>}
        onClick={() => {
          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          // table.setCreatingRow(
          //   createRow(table, {
          //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
          //   }),
          // );
        }}
      >
        Crear categoría
      </Button>
    )
  });


  return (
    <>
      {
        stateRequest.state && 
        <Alert variant="filled" severity={stateRequest.state}>{stateRequest.message}</Alert>
      }
        <MaterialReactTable table={table} localization={MRT_Localization_ES}/>
    </>
  );
};



const validateRequired = (value) => (value !== undefined && value !== null) && !!String(value).trim().length;
function validateCategory(property) {
  return {
    name: !validateRequired(property.name)? "El nombre de la categoría es requerido": ""
  };
}

export default MainCategories
