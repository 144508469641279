import React, { useEffect } from 'react'
import { Alert, Avatar, Box, Button, Paper, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import loginBackground from '../../assets/loginBackground.jpg'
import logoBlack from '../../assets/LogoBlack.jpeg'
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getLogon } from '../../services/login_services';
import { setExpTime } from '../../redux/Login/slices/cookiesSlice';

const Login=()=>{
    const dispatch = useDispatch();
    const {
      register,
      getValues,
      setError,
      formState: { errors },
      clearErrors,
    } = useForm({ defaultValues: { user: "", password: "" } });
    
    const [errorRequest, setErrorRequest] = useState({exists: false, message: ""})
    const [success, setSuccess] = useState(false)
    //eslint-disable-next-line
    const [cookies, setCookie] = useCookies(['user']);
    const {token, CTTO_v2} = cookies;
    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('lg'));


    useEffect(() => {
      if ((token && CTTO_v2) && (token !== 'undefined' && CTTO_v2 !== 'undefined')) {
        window.location.href = '/admin';    
      }
    }, [token, CTTO_v2])
    

    const handleLogin = async () => {
        const {user, password} = getValues();
        const validationError = validateErrors();
        if (validationError){   return  }
        await sendData(user, password)
    }

    const sendData = async(user, password) =>{
      try {
            const combinedString = `${user}:${password}`;
            const auth = btoa(combinedString);
            const {status, result} = await getLogon(auth)

            if (status !== 200) {
                if (status === 401) {
                    setErrorRequest({exists: true, message: "Usuario no autorizado"})
                    return
                } else{
                    setErrorRequest({exists: true, message: "Error de Login"})
                    return
                }
            } else {
              setErrorRequest({exists: false, message: ""})
              setSuccess(true)
            } //Si el status es 200, error es false
            //Se setean las cookies
            await setCookies(result)
           
        } catch (error) {
            console.log(error)
        }
    }

    const setCookies = async(params) =>{
        try {
            const {expiration, user} = params;
            const currentDate = new Date();
            const expirationDate = new Date(expiration);
            // Calcula la diferencia en milisegundos
            let differenceInMilliseconds = expirationDate - currentDate;
            const expirationInSeconds = Math.floor(differenceInMilliseconds / 1000);
            //Set cookies
            // Crear una promesa para setCookie
            const setCookiePromise = new Promise((resolve, reject) => {
              setCookie('token', params['x-csrf-token'], { maxAge: expirationInSeconds });
              setCookie('CTTO_v2', params['set-cookie'].split('=')[1], { maxAge: expirationInSeconds, sameSite:'none', secure:true });
              setCookie('user', user, { maxAge: expirationInSeconds });
              resolve(); // La promesa se resuelve inmediatamente, ya que setCookie es síncrono
            });

            // Crear una promesa para dispatch
            const dispatchPromise = new Promise((resolve, reject) => {
                // 30 segundos antes de cerrar la sesión, le avisamos al usuario que se cerrará
                dispatch(setExpTime(expirationDate));
                resolve(); // La promesa se resuelve inmediatamente, ya que dispatch es síncrono
            });

            // Esperar a que ambas promesas se resuelvan antes de redirigir
            await Promise.all([setCookiePromise, dispatchPromise]);
            // Redirecciona a otra ruta
            window.location.href = '/admin';            
        } catch (error) {
            console.log(error)
        }
    }

    const validateErrors = () => {
      const { user, password } = getValues();
  
      if (!user) {
        setError("user", { message: "El usuario no puede estar vacío" });
        return true;
      } else {
        clearErrors(["user"]);
      }
      if (!password) {
        setError("password", { message: "La contraseña no puede estar vacía" });
        return true;
      } else {
        clearErrors(["password"]);
      }
      return false
    };

    return (
      <Box
        height="100vh"
        color={"text.main"}
        display={"flex"}
        sx={{
          backgroundImage:`url(${loginBackground})`,
          //backgroundSize: 'cover', 
          backgroundPosition: 'center'
        }}
        alignItems="center"
      >
        <Paper
          elevation={10}
          sx={{
            padding: 5,
            width: isMobile ? 250 : 333,
            margin: "20px auto",
            height: (errorRequest.exists || success) ? "90%" : "80%",
            backgroundColor: 'rgba(66, 66, 66, 0.9)',
          }}
        >
          <Box align="center" mb={2}>
          <Avatar
          sx={{
            width: 88,
            height: 88,
            backgroundColor: '#141b2d',
            marginBlock: ".5rem",
            '& img': {
              width: '100%',
              height: '100%',
              objectFit: 'cover', // Para asegurar que la imagen se ajuste correctamente dentro del Avatar
            }
          }}
        >
          <img src={logoBlack} alt="logo" />
        </Avatar>
            <Typography variant="h3" color="textPrimary">
              Ingresar
            </Typography>
          </Box>
          <TextField
            variant="standard"
            label="Usuario"
            placeholder="Usuario"
            fullWidth
            required
            sx={{marginBlock:".4rem"}}
            {...register("user")}
            onBlur={validateErrors}
            error= {!!errors.user}
            helperText ={errors.user && errors.user.message}
            onKeyDown={(ev)=>{if(ev.key === "Enter"){handleLogin()}}}
          />
          <TextField
            variant="standard"
            label="Contraseña"
            placeholder="Contraseña"
            type="password"
            fullWidth
            required
            sx={{mb:"2rem"}}
            {...register("password")}
            onBlur={validateErrors}
            error= {!!errors.password}
            helperText ={errors.password && errors.password.message}
            onKeyDown={(ev)=>{if(ev.key === "Enter"){handleLogin()}}}
          />
          <Button
            type='submit'
            variant="contained"
            fullWidth
            style={{backgroundColor:'#22B9B9', height:"2rem", marginTop:2}}
            onClick={handleLogin}
          >
            Ingresar
          </Button>

          {errorRequest.exists && 
            <Alert severity="error" sx={{mt:"1rem", width:"100%", alignSelf:"center"}}>{errorRequest.message}</Alert>
          }
          {success && 
            <Alert severity="success" sx={{mt:"1rem", width:"100%"}}>Acceso correcto</Alert>
          }
        </Paper>
      </Box>
    );
}

export default Login