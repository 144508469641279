import {
  ArrowBack,
  ArrowForward,
  AttachMoney,
  Visibility,
  WhatsApp,
} from "@mui/icons-material";
import {
  Chip,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
  Avatar,
} from "@mui/material";
import React, { useState } from "react";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Property = ({ property }) => {
  const { title, description, address, images, price, currency } = property;
  const [changeImage, setChangeImage] = useState(0);

  <head>
    <meta charset="utf-8" />
    <link rel="icon" href="../../../../public/LogoBlack.png" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <meta
      name="description"
      content="Terra inmobiliaria, Reconquista, Santa Fe"
    />
    <meta
      name="keywords"
      content="inmobiliaria, reconquista, Santa Fe, alquiler y venta de casas, departamentos, terrenos, vender propiedades, servicio inmobiliario, terra"
    />
    <link rel="apple-touch-icon" href="/LogoBlack.png" />
    {/* personalizado */}
    <meta
      property="og:image"
      content={
        images && images[0]?.name
          ? `${SERVER_URL}/${images[0]?.name}`
          : "../../../../public/LogoBlack.png"
      }
    />
    <meta property="og:description" content={title} />
    <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
    <title>Terra</title>
  </head>;

  const handlePrevImage = () => {
    if (changeImage > 0) {
      setChangeImage(changeImage - 1);
    }
  };

  const handleNextImage = () => {
    if (changeImage < images?.length - 1) {
      setChangeImage(changeImage + 1);
    }
  };

  const handleClick = () => {
    window.open(`/propiedades/${property.id}`, "_blank");
  };

  const handleWhatsapp = () => {
    // Genera el texto automático con el enlace de la propiedad
    const message = encodeURIComponent(
      `¡Hola! Estoy interesado en esta propiedad ${window.location.href}propiedades/${property.id}`
    );
    // URL de la API de WhatsApp con el número del vendedor y el texto automático
    const url = `https://api.whatsapp.com/send?phone=+543482388020&text=${message}`;
    // Abre la conversación de WhatsApp en una nueva pestaña
    window.open(url, "_blank");
  };

  return (
    <Card
      sx={{
        maxWidth: 340,
        bgcolor: "background.main",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        borderRadius: 3,
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Añade una sombra sutil para resaltar la tarjeta
      }}
    >
      <CardMedia
        image={
          images && images[changeImage]?.name
            ? `${SERVER_URL}/${images[changeImage]?.name}`
            : ""
        }
        title={title}
        onClick={(e) => {
          if (!e.target.closest("button")) {
            // Verifica si el evento se originó desde un botón dentro del CardMedia
            handleClick(); // Ejecuta handleClick solo si el evento no se originó desde un botón
          }
        }}
        sx={{
          height: 220,
          width: "100%",
          position: "relative",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          transition: " 0.5s ease-in-out"
        }}
        onTouchStart={(e) => {
          let startX = e.touches[0].pageX;
          const handleTouchMove = (event) => {
            const diff = event.touches[0].pageX - startX;
            if (diff > 10) {
              handlePrevImage();
              startX = event.touches[0].pageX;
            } else if (diff < -10) {
              handleNextImage();
              startX = event.touches[0].pageX;
            }
          };
          document.addEventListener("touchmove", handleTouchMove);
          document.addEventListener("touchend", () => {
            document.removeEventListener("touchmove", handleTouchMove);
          });
        }}
      >
        {changeImage !== 0 && (
          <IconButton
            disabled={changeImage === 0}
            onClick={handlePrevImage}
            sx={{
              zIndex: 1,
              position: "absolute",
              left: 0,
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                borderRadius: "50%",
              },
              transition: "background-color 0.3s ease-in-out", // Transición aplicada al botón previo
            }}
          >
            <ArrowBack sx={{ color: "white" }} />
          </IconButton>
        )}

        {changeImage !== images?.length - 1 && (
          <IconButton
            disabled={changeImage === images?.length - 1}
            onClick={handleNextImage}
            sx={{
              zIndex: 1,
              position: "absolute",
              right: 0,
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                borderRadius: "50%",
              },
              transition: "background-color 0.3s ease-in-out", // Transición aplicada al botón siguiente
            }}
          >
            <ArrowForward sx={{ color: "white" }} />
          </IconButton>
        )}
      </CardMedia>

      <CardContent sx={{ flex: 1, overflow: "hidden" }}>
        {price && (
          <Chip
            icon={currency === "usd" ? <Avatar sx={{bgcolor:"transparent", fontSize:".9rem", m:-5}}>USD</Avatar> : <AttachMoney />}
            label={price}
            sx={{ width: "100%", my: "0.5rem", fontSize:"1rem"}}
          />
        )}

        <Typography
          variant="body1"
          sx={{ fontWeight: 600, marginBottom: "0.3rem" }}
        >
          {title.toUpperCase()}
        </Typography>
        {description && (
          <Typography
            variant="body2"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              my: ".4rem",
            }}
          >
            {description}
          </Typography>
        )}
        {address && (
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ fontWeight: 600 }}
          >
            {address}
          </Typography>
        )}
      </CardContent>
      <CardActions
        sx={{
          justifyContent: "space-between",
          backgroundColor: "terra.primary",
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
        }}
      >
        <Button
          size="small"
          color="btntxt"
          endIcon={<WhatsApp />}
          onClick={handleWhatsapp}
        >
          Consultar
        </Button>
        <Button
          size="small"
          color="btntxt"
          endIcon={<Visibility />}
          onClick={handleClick}
        >
          Ver
        </Button>
      </CardActions>
    </Card>
  );
};
export default Property;
