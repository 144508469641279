import React from 'react';
import { ErrorOutline } from '@mui/icons-material';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

const NotFound = () => {

  const {breakpoints} = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="80vh"
      paddingInline={isMobile && "1rem"}
      gap={"1rem"}
    >
      <ErrorOutline color="error" style={{ fontSize: 80, marginBottom: 20 }} />
      <Typography variant={isMobile ? "h5" : "h3"} color="textSecondary" >
        Oops! Something went wrong.
      </Typography>
      <Typography variant="h2" color="error">
        404
      </Typography>
      <Typography variant="h6" color="textSecondary">
        Page Not Found
      </Typography>
    </Box>
  );
};

export default NotFound;
  