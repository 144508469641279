import { configureStore } from "@reduxjs/toolkit";
import cookiesSlice from "./Login/slices/cookiesSlice";
import configSlice from "./Config/slices/configSlice";
import adminSlice from "./Admin/adminSlice";

export default configureStore({
    reducer:{
        cookies: cookiesSlice,
        config: configSlice,
        admin:adminSlice
    }
})